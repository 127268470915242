

















































































import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';

import {Network, Node, Organization, QuorumSet} from '@stellarbeat/js-stellar-domain';
import AddValidatorsTable
    from '@/components/node/tools/simulation/add-validators-table.vue';
import Store from '@/store/Store';
import {
    BButton,
    BDropdown, BDropdownDivider,
    BDropdownForm, BDropdownHeader, BDropdownItem,
    BFormGroup,
    BFormInput, BIconDownload, BIconPencil, BIconPlusCircle,
    BIconSearch, BIconThreeDotsVertical, BIconXCircle,
    BModal,
    VBModal
} from 'bootstrap-vue';
import StellarCoreConfigurationGenerator
    from '@stellarbeat/js-stellar-domain/lib/stellar-core-configuration-generator';
import AddOrganizationsTable from '@/components/node/tools/simulation/add-organizations-table.vue';

@Component({
    components: {
        AddOrganizationsTable,
        AddValidatorsTable,
        BModal: BModal,
        BIconSearch: BIconSearch,
        BDropdown: BDropdown,
        BDropdownForm: BDropdownForm,
        BButton: BButton,
        BFormGroup: BFormGroup,
        BFormInput: BFormInput,
        BIconPencil: BIconPencil,
        BIconXCircle: BIconXCircle,
        BDropdownItem: BDropdownItem,
        BIconPlusCircle: BIconPlusCircle,
        BDropdownHeader: BDropdownHeader,
        BIconThreeDotsVertical: BIconThreeDotsVertical,
        BIconDownload: BIconDownload,
        BDropdownDivider: BDropdownDivider
    },
    directives: {'b-modal': VBModal}
})
export default class QuorumSetActions extends Vue {
    @Prop()
    public quorumSet!: QuorumSet;
    @Prop({default: null})
    public parentQuorumSet!: QuorumSet;
    @Prop()
    public level!: number;

    public editingThreshold: boolean = false;
    public newThreshold: number = this.quorumSet.threshold;
    public id: number = Math.ceil(Math.random() * 1000);
    public validatorsToAdd: string[] = [];
    public organizationsToAdd: Organization[] = [];
    public inputState: boolean | null = null;
    protected tomlNodesExport:string = '';

    get store(): Store {
        return this.$root.$data.store;
    }

    get network():Network {
        return this.store.network;
    }

    get selectedNode() {
        return this.store.selectedNode;
    }

    public deleteQuorumSet() {
        this.store.deleteInnerQuorumSet(this.quorumSet, this.parentQuorumSet);
    }

    public get possibleOrganizationsToAdd() {
        let trustedOrganizations = this.network.getTrustedOrganizations(this.quorumSet).map(org => org.id);

        return this.store.network.organizations
            .filter((organization) => trustedOrganizations.indexOf(organization.id) < 0);
    }

    public get possibleValidatorsToAdd() {
        return this.store.network.nodes.filter((node: Node) =>
            node.isValidator
            && QuorumSet.getAllValidators(this.store.selectedNode!.quorumSet).indexOf(node.publicKey!) < 0);
    }

    public addValidatorsToQuorumSet(toQuorumSet: QuorumSet, validators: string[]) {
        this.store.addValidators(toQuorumSet, validators);
    }

    public addOrganizationsToQuorumSet(toQuorumSet: QuorumSet, organizations: Organization[]) {
        this.store.addOrganizations(toQuorumSet, organizations);
    }

    public addQuorumSet() {
        this.store.addInnerQuorumSet(this.quorumSet);
        this.$emit('expand');
    }

    public saveThresholdFromInput() {
        if (this.newThreshold <= 0) {
            this.inputState = false;
            return;
        }

        this.inputState = null;
        this.editingThreshold = false;
        (this.$refs.dropdown as any).hide(true);
        this.store.editQuorumSetThreshold(this.quorumSet, Number(this.newThreshold));
    }

    onValidatorsSelected(validators: Node[]) {
        this.validatorsToAdd = validators.map((validator: Node) => validator.publicKey!);
    }

    onOrganizationsSelected(organizations:Organization[]){
        this.organizationsToAdd = organizations;
    }

    validatorsToAddModalOk(bvEvent: any, modalId: string) {
        if (this.validatorsToAdd.length > 0) {
            this.addValidatorsToQuorumSet(
                this.quorumSet,
                this.validatorsToAdd
            );
            this.$emit('expand');
        }
    }

    organizationsToAddModalOk(bvEvent: any, modalId: string) {
        if (this.organizationsToAdd.length > 0) {
            this.addOrganizationsToQuorumSet(
                this.quorumSet,
                this.organizationsToAdd
            );
        }
    }

    loadTomlExport() {
        let stellarCoreConfigurationGenerator = new StellarCoreConfigurationGenerator(this.network);
        this.tomlNodesExport = stellarCoreConfigurationGenerator.quorumSetToToml(this.quorumSet);
    }
}
