













































































































import {Component, Prop, Watch} from 'vue-property-decorator';
    import Vue from 'vue';
    import {Network, Node, PublicKey, QuorumSet} from '@stellarbeat/js-stellar-domain';
    import Store from '@/store/Store';
    //import AsyncComputed from 'vue-async-computed-decorator';
    import {Delta, formatters, create, DiffPatcher} from 'jsondiffpatch';
    import 'jsondiffpatch/dist/formatters-styles/html.css';


    import {
        VBTooltip,
        BTable,
        BModal,
        VBModal,
        BButton,
        BBadge,
        BListGroup,
        BListGroupItem,
        BIconFileDiff,
        BButtonGroup,
        BIconClock,
        BButtonToolbar
    } from 'bootstrap-vue';

    interface Update {
        key: string;
        value: any;
    }

    @Component({
        components: {
            BTable,
            BModal,
            BButton,
            BListGroup,
            BListGroupItem,
            BBadge,
            BIconFileDiff,
            BButtonGroup,
            BIconClock,
            BButtonToolbar
        },
        directives: {'b-tooltip': VBTooltip, 'b-modal': VBModal},
    })
    export default class NodeLatestUpdates extends Vue {
        protected differ!: DiffPatcher;
        protected diffModalHtml: string = '<p>No update selected</p>';
        protected deltas: Map<string, Delta | undefined> = new Map();
        protected updatesPerDate: { date: string, updates: Update[], snapshot: any }[] = [];
        protected isLoading: boolean = true;
        protected failed: boolean = false;

        @Prop()
        protected node!: Node;

        showDiff(snapShot: any) {
            formatters.html.showUnchanged(true);
            this.diffModalHtml = formatters.html.format(this.deltas.get(snapShot.startDate)!, snapShot);
            (this.$refs['modal-diff'] as BModal).show();
        }

        get store(): Store {
            return this.$root.$data.store;
        }

        mapValidatorsToNames(quorumSet: QuorumSet) {
            quorumSet.validators = quorumSet.validators.map(
                (validator: PublicKey) => this.network.getNodeByPublicKey(validator) && this.network.getNodeByPublicKey(validator)!.name ? this.network.getNodeByPublicKey(validator)!.name : validator
            ) as [];

            quorumSet.innerQuorumSets = quorumSet.innerQuorumSets.map(quorumSet => this.mapValidatorsToNames(quorumSet));

            return quorumSet;
        }

        @Watch('node')
        async onNodeChanged(){
            await this.getSnapshots();
        }
        async getSnapshots() {
            let snapshots: any = [];
            try {

                this.deltas = new Map();
                this.updatesPerDate = [];
                snapshots = await this.store.fetchNodeSnapshotsByPublicKey(this.node.publicKey!);
                snapshots = snapshots.map((snapshot: any) => {
                    let quorumSet:QuorumSet;
                    if (snapshot.node.quorumSet === undefined)
                        quorumSet = new QuorumSet('empty', 0);
                    else
                        quorumSet = this.mapValidatorsToNames(snapshot.node.quorumSet);

                    return {
                        startDate: snapshot.startDate,
                        endDate: snapshot.endDate,
                        publicKey: snapshot.node.publicKey,
                        ip: snapshot.node.ip,
                        port: snapshot.node.port,
                        host: snapshot.node.host,
                        name: snapshot.node.name,
                        homeDomain: snapshot.node.homeDomain,
                        historyUrl: snapshot.node.historyUrl,
                        alias: snapshot.node.alias,
                        isp: snapshot.node.isp,
                        ledgerVersion: snapshot.node.ledgerVersion,
                        overlayVersion: snapshot.node.overlayVersion,
                        overlayMinVersion: snapshot.node.overlayMinVersion,
                        versionStr: snapshot.node.versionStr,
                        countryCode: snapshot.node.geoData.countryCode,
                        countryName: snapshot.node.geoData.countryName,
                        longitude: snapshot.node.geoData.longitude,
                        latitude: snapshot.node.geoData.latitude,
                        organizationId: snapshot.node.organizationId,
                        quorumSet: quorumSet
                    };
                });

                for (let i = snapshots.length - 2; i >= 0; i--) {
                    let updates: Update[] = [];
                    ['latitude', 'longitude', 'quorumSet', 'ip', 'port', 'countryName', 'countryCode', 'host', 'name', 'homeDomain', 'historyUrl', 'alias', 'isp', 'ledgerVersion', 'overlayVersion', 'overlayMinVersion', 'versionStr', 'organizationId']
                        .filter(key => key !== 'quorumSet' ? snapshots[i][key] !== snapshots[i + 1][key] : snapshots[i].quorumSet.hashKey !== snapshots[i + 1].quorumSet.hashKey)
                        .forEach(changedKey => updates.push({key: changedKey, value: snapshots[i][changedKey]}));

                    if (snapshots[i]['startDate'].getTime() !== snapshots[i + 1]['endDate'].getTime()) {
                        updates.push({key: 'archival', value: 'unArchived'});
                    }

                    this.updatesPerDate.push({date: snapshots[i].startDate, updates: updates, snapshot: snapshots[i]});
                    this.deltas.set(snapshots[i].startDate, this.differ.diff(snapshots[i + 1], snapshots[i]));
                }
                this.updatesPerDate.reverse();

            } catch (e) {
                console.log(e);
                this.failed = true;
            }
            this.isLoading = false;
            return snapshots;
        }

        async timeTravel(update: any) {
            this.store.isLoading = true;
            await this.store.fetchData(new Date(update.startDate));
            this.store.isLoading = false;
        }

        async mounted() {
            this.differ = create({
                objectHash(obj: any) {
                    if (obj && obj.hashKey) {
                        return obj.hashKey;
                    }
                },
                /*propertyFilter: function (name: string, context: any) {
                    return !['startDate', 'endDate'].includes(name);
                },*/
            });
            await this.getSnapshots();
        }

        get network(): Network {
            return this.store.network;
        }
    };
