























import {Component, Mixins, Prop} from 'vue-property-decorator';
import {
    BFormInput,
    BModal,
    BButton,
    BFormCheckbox,
    BFormSelect,
    BTable,
    BPagination,
    BBadge,
    BCard,
    BCardBody,
    BCardText,
    BCollapse,
    VBToggle,
    BCardHeader,
    BAlert
} from 'bootstrap-vue';
import {Node, QuorumSet, QuorumSlicesGenerator} from '@stellarbeat/js-stellar-domain';
import {StoreMixin} from '@/mixins/StoreMixin';

@Component({
    components: {
        BAlert,
        BFormInput,
        BModal,
        BButton,
        BFormCheckbox,
        BFormSelect,
        BTable,
        BPagination,
        BBadge,
        BCard,
        BCardBody,
        BCardText,
        BCollapse,
        BCardHeader
    },
    directives: {'b-toggle': VBToggle}
})
export default class QuorumSlices extends Mixins(StoreMixin) {

    @Prop()
    protected selectedNode!:Node;
    protected perPage: number = 10;
    protected currentPage: number = 1;
    protected items:any[] = [];
    protected fields:any = [{ key: 'slice', label: 'slices'}];

    get rows() {
        return this.items.length;
    }

    get length() {
        return this.items.length;
    }

    loadSlices() {
        let generator = new QuorumSlicesGenerator(false);
        let quorumSetWithSelf = new QuorumSet('temp', 2, [this.selectedNode.publicKey], [this.selectedNode.quorumSet]) ;
        this.items = generator.getSlices(quorumSetWithSelf).map(slice => { return {"slice": Array.from(new Set(slice))}});
    }
}
