






























import Vue from 'vue';
import {Component, Prop, Model} from 'vue-property-decorator';

import {Node} from '@stellarbeat/js-stellar-domain';
import {BFormInput, BIconShield, BPagination, BTable, VBTooltip} from 'bootstrap-vue';

@Component({
    name: 'add-validators-table',
    components: {
        BFormInput: BFormInput,
        BTable: BTable,
        BIconShield: BIconShield,
        BPagination: BPagination
    },
    directives: {
        'b-tooltip': VBTooltip
    }
})

export default class AddValidatorsTable extends Vue {
    @Prop()
    public validators!: Node[];

    public mode: string = 'multi';
    public optionShowInactive: number = 1;
    public sortBy: string = 'index';
    public sortDesc: boolean = true;
    public perPage: number = 10;
    public currentPage: number = 1;
    public filter: string = '';
    public totalRows: number = 1;
    public fields = [
        {key: 'name', sortable: true},
        {key: 'availability', sortable: true},
        {key: 'index', label: 'index (experimental)', sortable: true},
        {key: 'version', sortable: true}
    ];

    get nodes(): any[] {
        return this.validators
            .map((node) => {
                return {
                    name: node.displayName,
                    version: node.versionStr,
                    isFullValidator: node.isFullValidator,
                    index: node.index,
                    publicKey: node.publicKey
                };
            });
    }

    rowSelected(items: Node[]) {
        this.$emit('validators-selected', items);
    }

    public onFiltered = (filteredItems: any[]) => {
        this.totalRows = 1;
        (this.$refs.paginator as any)._data.currentPage = 1;
        (this.$refs.paginator as any)._data.localNumPages = Math.round(filteredItems.length / this.perPage);
        //reactivity doesn't work on currentPage and totalRows. why?
    };

    mounted() {
        // Set the initial number of items
        this.totalRows = this.nodes.length;
    }

}
