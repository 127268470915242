
















































































































































import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import HistoryCard from '@/components/charts/history-card.vue';
import Store from '@/store/Store';
import NodeInfo from '@/components/node/node-cards/node-info.vue';
import NodeExtraInfo from '@/components/node/node-cards/node-extra-info.vue';
import NodeQuorumSetValidators from '@/components/node/node-cards/node-quorum-set-validators.vue';
import NodeStatistics24HValidating
    from '@/components/node/node-cards/statistics/node-statistics-24H-validating.vue';
import NodeStatistics24HActive from '@/components/node/node-cards/statistics/node-statistics-24H-active.vue';
import NodeStatistics30DValidating
    from '@/components/node/node-cards/statistics/node-statistics-30D-validating.vue';
import NodeStatistics30DOverloaded
    from '@/components/node/node-cards/statistics/node-statistics-30D-overloaded.vue';
import NodeIndex from '@/components/node/node-cards/statistics/node-index.vue';
import NodeStatistics30DActive from '@/components/node/node-cards/statistics/node-statistics-30D-active.vue';
import NodeTrustedBy from '@/components/node/node-cards/node-trusted-by.vue';
import NodeLatestUpdates from '@/components/node/node-cards/node-latest-updates.vue';
import LazyHydrate from 'vue-lazy-hydration';
import {
    BAlert,
} from 'bootstrap-vue';
import {Network, QuorumSetService} from '@stellarbeat/js-stellar-domain';

@Component({
    components: {
        NodeLatestUpdates: NodeLatestUpdates,
        NodeTrustedBy,
        NodeStatistics30DActive,
        NodeIndex,
        NodeStatistics30DOverloaded,
        NodeStatistics30DValidating,
        NodeStatistics24HActive,
        NodeStatistics24HValidating,
        NodeQuorumSetValidators,
        NodeExtraInfo, NodeInfo, HistoryCard,
        LazyHydrate,
        BAlert
    }
})

export default class NodeDashboard extends Vue {

    get store(): Store {
        return this.$root.$data.store;
    }

    get network(): Network {
        return this.store.network;
    }

    get selectedNode() {
        return this.store.selectedNode;
    }
}
