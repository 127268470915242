










































import {Component, Prop} from 'vue-property-decorator';
import Vue from 'vue';
import {Network, Node} from '@stellarbeat/js-stellar-domain';
import Store from '@/store/Store';
import {
    BAlert,
    BBadge,
    BButton,
    BIconClipboard,
    BIconExclamationTriangle,
    BIconShield,
    VBTooltip
} from 'bootstrap-vue';
import FullValidatorTitle from '@/components/node/full-validator-title.vue';

@Component({
    components: {
        FullValidatorTitle,
        BIconExclamationTriangle: BIconExclamationTriangle, BAlert: BAlert, BBadge: BBadge, BIconClipboard: BIconClipboard, BButton:BButton, BIconShield: BIconShield},
    directives: {'b-tooltip': VBTooltip}
})
export default class NodeInfo extends Vue {
    @Prop()
    protected node!: Node;

    get store(): Store {
        return this.$root.$data.store;
    }

    get network(): Network {
        return this.store.network;
    }

    copyPublicKey() {
        this.$copyText(this.node.publicKey!);
    }
};
