



















import {Component, Prop} from 'vue-property-decorator';
import Vue from 'vue';
import {Network, Node} from '@stellarbeat/js-stellar-domain';
import Store from '@/store/Store';
import Chart from 'chart.js';
import Gauge from '@/components/charts/gauge.vue';

@Component({
    components: {Gauge}
})
export default class NodeStatistics30DOverloaded extends Vue {
    @Prop()
    protected node!: Node;

    get store(): Store {
        return this.$root.$data.store;
    }

    get network(): Network {
        return this.store.network;
    }
};
