














































































import Vue from 'vue';
import {Component, Prop, Watch} from 'vue-property-decorator';
import Store from '@/store/Store';
import StellarCoreConfigurationGenerator
    from '@stellarbeat/js-stellar-domain/lib/stellar-core-configuration-generator';
import QuorumSetDropdown from '@/components/node/sidebar/quorum-set-dropdown.vue';
import NavLink from '@/components/side-bar/nav-link.vue';
import SimulateNewNode from '@/components/node/tools/simulation/simulate-new-node.vue';
import {Node, QuorumSet} from '@stellarbeat/js-stellar-domain';
import OrganizationsDropdown from '@/components/network/sidebar/organizations-dropdown.vue';
import OrganizationValidatorsDropdown from '@/components/node/sidebar/organization-validators-dropdown.vue';
import SideBar from '@/components/side-bar/side-bar.vue';
import {BBadge, BModal, VBModal} from 'bootstrap-vue';
import QuorumSlices from '@/components/node/tools/quorum-slices.vue';

@Component({
    components: {
        QuorumSlices,
        SideBar,
        OrganizationValidatorsDropdown,
        OrganizationsDropdown,
        SimulateNewNode,
        NavLink,
        QuorumSetDropdown,
        BModal: BModal,
        BBadge: BBadge
    },
    directives: {
        'b-modal': VBModal
    }
})
export default class NodeSideBar extends Vue {
    public quorumSetExpanded: boolean = true;
    protected tomlNodesExport: string = '';

    get store(): Store {
        return this.$root.$data.store;
    }

    get selectedNode() {
        return this.store.selectedNode;
    }

    get hasOrganization() {
        return this.selectedNode && this.selectedNode.organizationId && this.network.getOrganizationById(this.selectedNode.organizationId);
    }

    get organization() {
        if (this.hasOrganization && this.selectedNode)
            return this.network.getOrganizationById(this.selectedNode.organizationId!);
        else return null;
    }

    get network() {
        return this.store.network;
    }

    get nodeType() {
        return this.selectedNode!.isValidator ? (this.selectedNode!.isFullValidator ? 'Full Validator' : 'Validator') : 'Watcher';
    }

    loadTomlExport() {
        let stellarCoreConfigurationGenerator = new StellarCoreConfigurationGenerator(this.network);
        this.tomlNodesExport = stellarCoreConfigurationGenerator.nodesToToml([this.selectedNode!]);
    }

    getDisplayName(node: Node) {
        if (node.name)
            return node.name;

        return node.publicKey!.substr(0, 7) + '...' + node.publicKey!.substr(50, 100);
    }
}
