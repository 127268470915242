



































import {Component, Prop, Mixins} from 'vue-property-decorator';
import {Network, Node, Organization} from '@stellarbeat/js-stellar-domain';
import NavLink from '@/components/side-bar/nav-link.vue';
import {DropdownMixin} from '@/components/side-bar/dropdown-mixin';
import NavPagination from '@/components/side-bar/nav-pagination.vue';
import NodeActions from '@/components/node/sidebar/node-actions.vue';
import Store from '@/store/Store';
import {BIconSearch} from 'bootstrap-vue';
import OrganizationActions from '@/components/organization/sidebar/organization-actions.vue';

@Component({
    components: {
        OrganizationActions,
        NodeActions,
        NavPagination,
        NavLink,
        BIconSearch: BIconSearch
    },
})
export default class OrganizationValidatorsDropdown extends Mixins(DropdownMixin) {
    @Prop()
    public organization!: Organization;

    get store():Store {
        return this.$root.$data.store;
    }

    get network(): Network {
        return this.store.network;
    }

    get validators() {
        return this.organization.validators.map(validator => this.network.getNodeByPublicKey(validator));
    }

    getDisplayName(validator: Node) {
        if(validator.name)
            return validator.name;

        return validator.publicKey!.substr(0, 7) + '...' + validator.publicKey!.substr(50, 100)
    }

    public selectValidator(validator: Node) {
        if(this.$route.params.publicKey && this.$route.params.publicKey === validator.publicKey)
            return;

        this.$router.push({
            name: 'node-dashboard',
            params: {publicKey: validator.publicKey!},
            query: {'center': '1', 'no-scroll': '1', 'view': this.$route.query.view, 'network': this.$route.query.network, 'at': this.$route.query.at},
        });
    }
}
